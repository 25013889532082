import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today is the start of the inaugural `}<a parentName="p" {...{
        "href": "http://money2020.com/"
      }}>{`Money2020`}</a>{` conference in Las Vegas. PayPerks`}{`’`}{` own Arlyn Davich will be featured in a session titled "Innovative Solutions for the Financially Underserved"`}</p>
    <p>{`In the standing-room-only session, Arlyn shared the stage with Rachel Schneider and Arjan Schutte from CFSI and a handful of other innovators including Justine Zinkin, CEO, Neighborhood Trust Financial Partners, Richard Kang, Cofounder & CEO, Wipit, Mike Mondelli, CEO, L2C, Joanna Keri McFarland, First Vice President, Prepaid and Credit Cards, OneWest Bank and Mike Love, CTO, Mozido.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "621px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQElEQVQ4y12TW28bRRTH/Sn4BHwUBBIPFS8oCCEQtBJCSCWQEoj60qJQoqaQxkqhtAXRKk4iNSW31nXdxA52HF9jO7bXy9rx2rv22nvzbS9ee2dnBu26iULnYTRnZn5zzvzPOS78/2EBILWaCKHX9sc2gtASRdjpOgZynZ1BaGGM6UL26c/TMlseX4XOK4GSOOE5+uYF1QjHepcnjXjCZiB0AYhMiCyIEIQIY4GIsY9nWTIj6piVVFEHNVl/58/kGwuRN2e881/dAwfhEcbAggCeegaWVWfZEcRSk5ODy6kyFyi1I3W1esIlfIcf3t699MVv772/MP1XzHH56lMuCCHGeHHhlx+vfn2YSPOKmc3lK6KWpfkSxchT30lzt5J3lq/c3Hj3UepI1Gz4VBEXdlZ3l27T+2vRZCpIq2QHJcuttHeVeLlZSRTyBJPjtX26VxMU5Hg6G3bYHRMHE9mAZ/Ffmk0Uq7Ht1WQ8QT3zpB4/CBRqO6Tsy3MhuhdmNYpXHJnPwZWuQbK81+cnGTG3+/eBe3Jv5Z7X88fMB2/7A6EjTo8z/RfB6DPvXr1nns+iDVOSRglaujXMd/Bzz4Po0tTLxe+3/XurK8sEecJrqFSkju7fePLJW8VQADk5RAhBBG2YFNRq2/DF889j2eRhZP3WjP/+XJGVw5z5D6MH60PffpxcdW9NTmxd+QxhDKAJLNOCwIaZrtHo6LXQOrE2m84cpzjjmNMzdSVNsdcnv3TfnGPaRt63uTN9ceeHqXJ7oJtDC5oWtGxYHpi0pNTD69z2PJUKH5SEXMTPSd2Hd9wPv/144dKFbe9uQQJkpdWUeoSgONmyI7dhbQQygt6vFsprc81mM/J0pbX1Uyu++euNa9m71x5dnnAv/X5YN0q8Qkm6pI3OBHeNtaM7A1rWSkShIuq5kFfYmG3HNmKJ7PXPP7168aNwKk+3RxVJLYrqWGdCULWRddoYCBGCymmgb1gNoRtdmY9GQiHG8OVYpqMrQ8h0BhmuPwSviqTAO/BZ2hBCbFcnRVVQzdwJk6vyx40+2zUaPSPT7NPtAXBK+nyvukYWPIXt2QDwRNbY/qjWM4qiSghKUVDbA/O19h6X93/r8268Tr6xvAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/d03cd2b62ff219ffb52f31806b3eee5d/7f61c/Money2020UnderservedPanel.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/d03cd2b62ff219ffb52f31806b3eee5d/b2315/Money2020UnderservedPanel.webp 621w"],
            "sizes": "(max-width: 621px) 100vw, 621px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/d03cd2b62ff219ffb52f31806b3eee5d/e17e5/Money2020UnderservedPanel.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/d03cd2b62ff219ffb52f31806b3eee5d/3075e/Money2020UnderservedPanel.png 621w"],
            "sizes": "(max-width: 621px) 100vw, 621px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/d03cd2b62ff219ffb52f31806b3eee5d/3075e/Money2020UnderservedPanel.png",
            "alt": "Money2020UnderservedPanel",
            "title": "Money2020UnderservedPanel",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      